<template>
  <div class="qrcode-page flex-center" @click="closeModal">
    <div class="modal-content" id="modal-content">
      <div class="top">
        <div class="close-btn flex-center"><i class="fa fa-times"></i></div>
      </div>
      
      <div class="body scroll flex-center">
         <h3>Edit user</h3>
         <div class="picture">
            <div class="image-holder" @click="clickBtn">
                <img :src="img">
                <div class="cam-attach flex-center">
                    <i class="fa fa-camera"></i>
                </div>
                <input type="file" id="profile-picture" hidden @change="onFileChange">
            </div>
        </div>

       <form>
            <div>
                <label>Firstname</label>
                <div>
                  <input type="text" v-model="formData.firstname">
                </div>
              </div>
              <div>
                <label>Lastname</label>
                <div>
                  <input type="text" v-model="formData.lastname">
                </div>
              </div>
              <div>
                <label>Email</label>
                <div>
                  <input type="text" v-model="formData.email">
                </div>
              </div>
              <div>
              <label>Position</label>
              <div>
                <select v-model="formData.role">
                  <option v-for="(role, index) in roles" :key="index" :value="role" v-show="role !== getSecondaryUser.role">{{ role }}</option>
                </select>
              </div>
            </div>
              <div>
                <div class="spinner" v-if="loading">
                  <img src="https://www.thegibsonhotel.ie/wp-content/themes/gibson2017/images/loading.gif" alt="loading..." width="30">
                </div>
                <Button v-else @click.prevent="editUser" :style="{ color: '#fff', backgroundColor: '#159661', width: '176px', borderRadius: '5px', padding: '10px' }" text="Save changes"/>  
                <div class="success-msg" v-if="successMsg">
                  {{ successMsg }}
                </div>
                <div class="error-msg" v-if="errorMsg">
                  {{ errorMsg }}
                </div>
              </div>
          </form>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    Button
  },
  computed: {
      ...mapGetters([
          'getSecondaryUser',
      ])
  },
  mounted(){
    axios.get(`${this.$store.state.auth.apiUrl}/api/User/get-roles`)
    .then(res => {
      this.roles = res.data.data
    })
    .catch(err => {
      console.log(err)
    })
  },
  created(){
    setTimeout(() => {
      this.img = this.getSecondaryUser.pictureUrl
      this.formData = {
        image: '',
        firstname: this.getSecondaryUser.firstName,
        lastname: this.getSecondaryUser.lastName,
        email: this.getSecondaryUser.email,
        role: this.getSecondaryUser.role
      }
    }, 100)
  },
  data(){
    return {
      classes: ['qrcode-page flex-center', 'close-btn flex-center', 'fa fa-times'],
      formData: {
        image: '',
        firstname: "dwaine",
        lastname: "johnson",
        email: "newuser@vintomaper.com",
        role: "Security"
      },
      img: "",
      roles: [],
      successMsg: "",
      errorMsg: "",
      loading: false
    }
  },
  methods: {
    closeModal(e){
      if(this.classes.includes(e.target.className)){
        this.$router.push('/users')
      }
    },
    clickBtn(){
        document.getElementById('profile-picture').click()
    },
    onFileChange(e){
        const files = e.target.files || e.dataTransfer.files;
        if(!files.length)
            return
        this.formData.image = files[0]
        this.createImage(files[0])
    },
    createImage(file) {
        // const image = new Image();
        const reader = new FileReader();
        reader.onload = (e) => {
            this.img = e.target.result;
        };
        reader.readAsDataURL(file)
    },
    editUser(){
      this.loading = true
      const formData = new FormData();
      formData.append("UserId", this.getSecondaryUser.id)
      formData.append("ProfilePicture", this.formData.image)
      formData.append("FirstName", this.formData.firstname)
      formData.append("LastName", this.formData.lastname)
      formData.append("Email", this.formData.email)
      formData.append("Role", this.formData.role)

      axios.patch(`${this.$store.state.auth.apiUrl}/api/User/edit-user-with-roles`, formData)
      .then(res => {
        if(res.data.code == 200) {
          this.loading = false
          this.successMsg = res.data.shortDescription
          this.$store.dispatch('fetchSecondaryUsers')
        }
        console.log(res.data)
      })
      .catch(err => {
        this.loading = false
        this.errorMsg = err
        console.log(err.response.data)
      })
    }
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.7);
      padding: 10px 0px;
    }

    .modal-content {
      height: 100%;
      width: 500px;
      background: #fff;
      color: #262626;
      border-radius: 30px;
      padding: 20px;
      box-shadow: 0 0 6px 6px #00000035;
      /* margin-top: 10rem; */
    }

    .top {
      text-align: right;
      display: flex;
      justify-content: right;
    }

    .close-btn {
      height: 20px;
      width: 20px;
      font-size: 10px;
      border-radius: 100px;
      text-align: center;
      cursor: pointer;
      text-align: right;
      background-color: rgba(0, 0, 0, 0.152);
    }

    .close-btn:hover {
      background-color: rgba(0, 0, 0, 0.042);
    }

    .body {
        flex-direction: column;
        height: 100%;
        overflow: auto;
        padding-top: 54%;
        padding-bottom: 3rem;
    }

    .body h3 {
        margin-bottom: 20px;
    }

    .body > div {
        margin-bottom: 20px;
    }

    .image-holder {
        width: 180px;
        height: 180px;
        border-radius: 100%;
        margin: 0 auto;
        background-color:#DDF3FF;
        background-size: cover;
        overflow: hidden;
        text-align: center;
    }

    .image-holder img {
        width: 100%;
    }

    .picture {
        position: relative;
        margin: 0 auto;
        cursor: pointer;
    }

    .cam-attach {
        position: absolute;
        bottom: 0;
        right: 20px;
        z-index: 2;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: #25a244de;
        color: #fff;
        cursor: pointer;
    }

    .tag-name {
        color: #979797;
    }

    /* form  */

    
  .fake-input {
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        padding: 0 10px;
        color: #000;
        border: 1px solid #a7a7a7;
    }

    .fake-input input {
      border: none;
      width: 100%;
      flex: 8;
      font-size: 15px;
    }

    .fake-input div {
      flex: 1;
    }

    form > div {
      margin-bottom: 15px;
    }

    form > div:last-child {
      text-align: center;
      margin-top: 50px;
    }


    form label {
      font-size: 12px;
      color: #a7a7a7;
    }

    form input, form select {
      margin-top: 2px;
      border: 1px solid #a7a7a7;
      font-size: 15px;  
      width: 300px;
    }

    .btn {
        text-align: center;
    }

    
    .scroll::-webkit-scrollbar {
        width: 0.2em;
    }
    
    .scroll::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    outline: none;
    }
</style>